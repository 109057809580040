/* Стили для кнопки */
.custom-button {
    background-color: #1890ff; /* Изначальный цвет как при наведении */
    color: white;
    border: none;
  }
  
  .custom-button:hover {
    background-color: #1677d1; /* На пару тонов темнее при наведении */
  }
  
  /* Стили для переключателей */
  .custom-switch .ant-switch-checked {
    background-color: #1890ff; /* Изначальный цвет как при активации */
  }
  
  .custom-switch .ant-switch-checked:hover {
    background-color: #1677d1; /* На пару тонов темнее при наведении */
  }
  
  .custom-switch .ant-switch-inner {
    color: white;
  }
  
  /* Устанавливаем цвет текста для подписей */
  .text-black {
    color: black;
  }